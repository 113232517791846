@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
  }

  /* scrollbar */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and 엣지 */
    scrollbar-width: none; /* 파이어폭스 */
  }
}

input[type="radio"] {
  accent-color: #fc3f00;
}

.checkbox-orange {
  display: none;
}

.icon-checkbox-orange {
  width: 20px;
  height: 20px;
}

.checkbox-orange + .icon-checkbox-orange {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("./assets/images/checkbox.png");
}
.checkbox-orange:checked + .icon-checkbox-orange {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("./assets/images/checkbox-on.png");
}

.convenience-barcode svg {
  width: 100% !important;
  max-width: 300px;
  margin: 0 auto;
}
